import React, { useState, useContext, useEffect } from 'react'
import MetaTags from 'react-meta-tags';
import { CompanyContext } from '../../global_setting/Store';
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Header from './Header'
import Footer from './Footer'
import config from '../../../src/config.json'
import history from '../../history'
import FooterFixed from './FooterFixed'
import OfferSection from './sections/OfferSection'
import AboutSection from './sections/AboutSection'
import ServiceSection from './sections/ServiceSection'
import PackageSection from './sections/PackageSection';
import GallerySection from './sections/GallerySection';
import TestimonialSection from './sections/TestimonialSection';
import FacebookSection from './sections/FacebookSection';
import BlogSection from './sections/BlogSection';
import EventSection from './sections/EventSection';
import NewsletterSection from './sections/NewsletterSection';
import ContactSection from './sections/ContactSection';
import NearbyPlacesSection from './sections/NearbyPlacesSection';
import FacilitiesSection from './sections/FacilitiesSection';
import PromotionalBannerSection from './sections/PromotionalBannerSection';
import PromotionSection from './sections/PromotionSection';
import AwardsSection from './sections/AwardsSection';
import BookingBenefitsSection from './sections/BookingBenefitsSection';
import CustomFooterSection from './sections/CustomFooterSection';
import DesktopPopup from './datepickerpopup/DesktopPopup'
import MobilePopup from './datepickerpopup/MobilePopup'
import _debounce from 'lodash.debounce'
import CustomSection from './sections/CustomSection';


function Rooms() {
    const [companyDetails] = useContext(CompanyContext);
    let company_id_val = companyDetails && companyDetails.company_id;

    const [roomPageData, setRoomPageData] = useState();

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    let display_default_footer = true;
                    let all_hotel_rooms = []
                    const pageData = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-room-page-data/' + company_id + '/' + template_id
                    );
                    const result = await pageData.json();

                    if (result && result.room_details.data) {
                        for (var i = 0; i < result.room_details.data.length; i++) {
                            for (var j = 0; j < result.room_details.data[i].length; j++) {
                                all_hotel_rooms.push(result.room_details.data[i][j]);
                            }
                        }
                    }

                    if (all_hotel_rooms && result.room_services) {
                        for (let p = 0; p < all_hotel_rooms.length; p++) {
                            for (let q = 0; q < result.room_services.length; q++) {
                                if (all_hotel_rooms[p].hotel_id === result.room_services[q].hotel_id && all_hotel_rooms[p].room_type_id === result.room_services[q].room_type_id) {
                                    all_hotel_rooms[p].room_services = result.room_services[q].room_services;
                                }
                            }
                        }
                    }

                    if (result && result.room_page_data && result.room_page_data.page_section) {
                        for (let i = 0; i < result.room_page_data.page_section.length; i++) {
                            if (result.room_page_data.page_section[i].section_names === "Custom Footer") {
                                display_default_footer = false
                            }
                        }
                    }
                    
                    setRoomPageData({ "room_page_data": result && result.room_page_data, "page_section": result && result.room_page_data && result.room_page_data.page_section, "all_rooms": all_hotel_rooms, "all_hotels": result && result.all_hotels, "section_details": result && result.section_details,"display_default_footer": display_default_footer })

                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])

    const [scrolled, setscroll] = useState(false)
    if (scrolled === false) {
        window.scrollTo(0, 0)
    }
    window.addEventListener('scroll', function () {
        setscroll(true);
    });


    let video_banner_link = roomPageData && roomPageData.room_page_data && roomPageData.room_page_data.video_banner_link
    video_banner_link = video_banner_link && video_banner_link.slice(video_banner_link.indexOf("."), video_banner_link.length)
    video_banner_link = video_banner_link && video_banner_link.slice(video_banner_link.indexOf("/") + 1, video_banner_link.length)

    let display_video_banner_link = roomPageData && roomPageData.room_page_data && roomPageData.room_page_data.video_banner


    return (
        <div>
            {(roomPageData === undefined) ? <div className="loader"></div> : <Header />}

            {roomPageData && roomPageData.room_page_data &&
                <MetaTags>
                    <meta charSet="UTF-8" />
                    <title>{roomPageData.room_page_data.seo_title}</title>
                    <meta name="description" content={roomPageData.room_page_data.seo_description} />
                </MetaTags>
            }

            {roomPageData && roomPageData.room_page_data && roomPageData.room_page_data.banner &&
                <section className="banner_area" style={{ backgroundImage: "url(" + config.wbImagesEndpoint + '/' + roomPageData.room_page_data.banner + ")" }}>
                    <div className="container">
                        <div className="banner_inner_content">
                            <h3 >{roomPageData.room_page_data.banner_title}</h3>
                            <ul>
                                <li className="active"><a onClick={() => history.push(config.PATH + 'home')}>Home</a></li>
                                <li><a style={{ color: 'white' }}>{roomPageData.room_page_data.banner_title}</a></li>
                            </ul>
                        </div>
                    </div>
                </section>}


            {roomPageData && roomPageData.room_page_data && roomPageData.room_page_data.video_banner && <section>
                <div className="video-date-picker-wrapper">
                    <div className="video-wrapper">
                        <video playsInline="playsinline" autoPlay="autoplay" muted="muted" loop="loop">
                            <source src={config.wbImagesEndpoint + '/' + roomPageData.room_page_data.video_banner} type="video/mp4" />
                            <source src={config.wbImagesEndpoint + '/' + roomPageData.room_page_data.video_banner} type="video/ogg" />
                        </video>
                    </div>
                </div>
            </section>}


            {!display_video_banner_link && video_banner_link && <section>
                <div className="video-date-picker-wrapper">
                    <div className="video-container-youtube">
                        <iframe type="text/html" className="video-youtube" src={"https://www.youtube.com/embed/" + video_banner_link + "?&amp;autoplay=1&amp;loop=1&amp;rel=0&amp;showinfo=0&amp;controls=0&amp;color=white&amp;iv_load_policy=3&amp;"} allow="autoplay" frameBorder="0" allowFullScreen></iframe>
                    </div>
                </div>
            </section>}


            {roomPageData && roomPageData.room_page_data && roomPageData.room_page_data.page_title && <section className="spec_resort_area">
                <div className="container">
                    <div className="center_page_title">
                        <h2>{roomPageData.room_page_data.page_title}</h2>
                        <p dangerouslySetInnerHTML={{ __html: roomPageData.room_page_data.page_description }}></p>
                    </div>
                </div>
            </section>}




            {roomPageData && <RoomList data={roomPageData.all_rooms} hotel_list={roomPageData.all_hotels} section_data={roomPageData.section_details} />}

            {roomPageData && roomPageData.page_section && roomPageData.page_section.map(function (item, i) {

                if (item.section_names === "Promotional Banner") {
                    return (
                        <PromotionalBannerSection key={i} />
                    )
                }

                if (item.section_names === "Newsletter") {
                    return (
                        <NewsletterSection key={i} />
                    )
                }

                if (item.section_names === "About Us") {
                    return (
                        <AboutSection key={i} />
                    )
                }


                if (item.section_names === "Gallery") {
                    return (
                        <GallerySection key={i} />
                    )
                }

                if (item.section_names === "Contact Us") {
                    return (
                        <ContactSection key={i} />
                    )
                }

                if (item.section_names === "Services") {
                    return (
                        <ServiceSection key={i} />
                    )
                }


                if (item.section_names === "Nearby Places") {
                    return (
                        <NearbyPlacesSection key={i} />
                    )
                }

                if (item.section_names === "Testimonial") {
                    return (
                        <TestimonialSection key={i} />
                    )
                }


                if (item.section_names === "Facility") {
                    return (
                        <FacilitiesSection key={i} />
                    )
                }


                if (item.section_names === "Package") {
                    return (
                        <PackageSection key={i} />
                    )
                }

                if (item.section_names === "Blog") {
                    return (
                        <BlogSection key={i} />
                    )
                }

                if (item.section_names === "Event") {
                    return (
                        <EventSection key={i} />
                    )
                }

                if (item.section_names === "Offer") {
                    return (
                        <OfferSection key={i} />
                    )
                }


                if (item.section_names === "Facebook") {
                    return (
                        <FacebookSection key={i} />
                    )
                }
                if (item.section_names === "Promotions") {
                    return (
                        <PromotionSection key={i} />

                    )
                }

                if (item.section_names === "Awards") {
                    return (
                        <AwardsSection key={i} />
                    )
                }


                if (item.section_names === "Booking Benfits") {
                    return (
                        <BookingBenefitsSection key={i} />
                    )
                }

                if (item.section_type === "custom") {
                    return (
                        <CustomSection key={i} section_id={item.id} name={item.section_names}/>
                    )
                }
                
                if (item.section_names === "Custom Footer") {
                    return (
                        <CustomFooterSection key={i} />
                    )
                }
            })}

            {roomPageData && roomPageData.display_default_footer && roomPageData.display_default_footer === true && <Footer />}
            {roomPageData && <FooterFixed />}
        </div>

    );
}

function RoomList(props) {
    let list_data = props.data;
    let hotel_list = props.hotel_list
    let section_data = props.section_data

    let websitefont = '';
    const [modal, setModal] = useState(false);
    const [hotelId, setHotelId] = useState(hotel_list && hotel_list[0].hotel_id);
    const hotelName = (e) => {
        setHotelId(parseInt(e));
    }


    const [screen_size, setScreensize] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = _debounce(() => setScreensize(window.innerWidth), 100)
        window.addEventListener('resize', handleResize);
    });

    return (
        <div>
            <section className="explor_room_area explore_room_list page-banner">
                <div className="container">
                    <div className="explor_title row m0">
                        {section_data && <div className="pull-left">
                            <div className="left_ex_title">
                                <h2 >{section_data.section_title}</h2>
                            </div>
                        </div>}
                        {hotel_list && hotel_list.length > 1 &&
                            <div className="pull-right">
                                <div className="page-header text-center text-uppercase">
                                    <select className="form-control select-form select-hotel" onChange={(e) => { hotelName(e.target.value) }}>
                                        {hotel_list && hotel_list.map(function (item, i) {
                                            return (
                                                <option key={i} value={item.hotel_id}>{item.hotel_name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>}
                    </div>


                    <div className="row explor_room_item_inner">
                        {list_data && list_data.map(function (item, id) {
                            
                            if (hotelId === item.hotel_id) {
                                var send_room_type = item.room_type.replace(/[^a-zA-Z ]/g, "");
                                send_room_type = send_room_type.replace(/ +/g, "_");
                                send_room_type = send_room_type.toLowerCase();
                                return (
                                    <div className="col-md-4 col-sm-6" key={id}>
                                        <div className="explor_item">
                                            <OwlCarousel
                                                className="owl-theme"
                                                items={1}
                                                autoplay={true}
                                                autoplaySpeed={950}
                                                loop
                                                nav

                                            >
                                                {item.room_image.map(function (items, i) {



                                                    return (
                                                        <div className="item" key={i}>
                                                            <div className="dotts">
                                                                <Link className="room_image" to={{
                                                                    pathname: config.PATH + '' + send_room_type,
                                                                    roomdetails: item
                                                                }}>
                                                                    <img src={config.imageEndpoint + '/' + items} alt="" />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </OwlCarousel>
                                            <div className="explor_text">
                                                <Link to={{
                                                    pathname: config.PATH + '' + send_room_type,
                                                    roomdetails: item
                                                }}><h4 style={{ fontFamily: websitefont, textAlign: "center" }}>{item.room_type}</h4></Link>
                                                <div className="text-center">
                                                    <div className="card-room">
                                                        <div className="text-center"><i className="fa fa-search"></i></div>
                                                        <h6>{item.room_view_type}</h6>
                                                    </div>
                                                    <div className="card-room">
                                                        <div className="text-center"><i className="fa fa-bed" ></i></div>
                                                        <h6>{item.bed_type}</h6>
                                                    </div>
                                                    <div className="card-room">
                                                        <div className="text-center"><i className="fa fa-window-maximize" ></i></div>
                                                        <h6>{item.room_size_value} {item.room_size_unit}</h6>
                                                    </div>
                                                </div>
                                                <br />
                                                <div className="explor_footer">
                                                    <div className="pull-left">
                                                        <Link className="book_now_btn" to={{
                                                            pathname: config.PATH + '' + send_room_type,
                                                            roomdetails: item
                                                        }} style={{ fontFamily: websitefont }}>View details</Link>
                                                    </div>

                                                    <div className="pull-right">
                                                        <button className="book_now_btn" onClick={()=>setModal(true)}>Book Now</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        })
                        }
                    </div>
                    {modal && (screen_size>768) &&<DesktopPopup data={hotelId} modal_value={modal} setModalValue={setModal}/>}
                    {modal && (screen_size<768) && <MobilePopup data={hotelId} modal_value={modal} setModalValue={setModal}/>}
                </div>
            </section>
        </div>

    )
}


export default Rooms;