import React, { useState, useEffect, useContext } from 'react';
import { CompanyContext } from '../../../global_setting/Store';
import config from '../../../../src/config.json'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom'

const BlogsSection = () => {

    const [companyDetails] = useContext(CompanyContext);
    const [blogSectionData, setBlogSectionData] = useState();
    let company_id_val = companyDetails && companyDetails.company_id;

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    const blogSection = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-blog-section-data/' + company_id + '/' + template_id
                    );
                    const result = await blogSection.json();

                    setBlogSectionData({ "blog_data": result && result.hotel_blogs, "section_details": result && result.section_details });
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])

    let websitefont = '';
    let section_details = blogSectionData && blogSectionData.section_details    

    return (
        <div>

            <div className="container">
                {section_details && <div className="center_title">
                    <h2>{section_details.section_title}</h2>
                    <p dangerouslySetInnerHTML={{ __html: section_details.section_description }}></p>
                </div>}
            </div>

            <div className={section_details && section_details.background_image && "parallax-bg"} style={section_details && { backgroundImage: "url(" + config.wbImagesEndpoint + '/' + section_details.background_image + ")" }}>
                {blogSectionData && blogSectionData.blog_data && blogSectionData.blog_data.length > 0 && <div className="packages">
                    <section className="event_two_area" style={{ padding: "30px 0px" }}>
                        <div className="container">
                            <div className="row event_two_inner">
                                <OwlCarousel
                                    className="owl-theme"
                                    autoplay={true}
                                    nav={true}
                                    loop
                                    margin={10}
                                    responsive={{
                                        0: {
                                            items: 1
                                        },
                                        800: {
                                            items: 2
                                        },
                                        1200: {
                                            items: 3
                                        },
                                    }}

                                >
                                    {blogSectionData.blog_data.map(function (item, i) {
                                        var send_title = item.blog_title.replace(/[^a-zA-Z0-9 ]/g, "");
                                        send_title = send_title.replace(/ +/g, "_");
                                        send_title = send_title.replace(",", "");
                                        send_title = send_title.toLowerCase();
                                        return (
                                            <div className="item" key={i}>
                                                <div className="col-md-4 col-sm-4" style={{ width: "100%" }}>
                                                    <div className="explor_item">
                                                    <div className="event_two_item">
                                                        <Link className="event_two_img" to={{
                                                            pathname: config.PATH + '' + send_title
                                                        }}>
                                                            <img src={config.wbImagesEndpoint + '/' + item.blog_image} alt={item.blog_image_alt} style={{ height: "245px" }} />
                                                        </Link>
                                                        <div className="event_two_text">
                                                            <div className="media">

                                                                <div className="media-body">
                                                                    <Link to={{
                                                                        pathname: config.PATH + '' + send_title
                                                                    }}><h4>{item.blog_title}</h4>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                            <hr></hr>
                                                            <br />
                                                            <div className="text-center">
                                                                <Link className="book_now_btn" to={{
                                                                    pathname: config.PATH + '' + send_title
                                                                }} style={{ fontFamily: websitefont, textDecoration: "none" }}>read more</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </OwlCarousel>
                            </div>
                        </div>
                    </section>
                </div>}
            </div>
        </div>
    );
}

export default BlogsSection;