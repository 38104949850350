import React, { useState, useEffect, useContext } from 'react';
import MetaTags from 'react-meta-tags';
import { CompanyContext, WebsiteDetailsContext } from '../../global_setting/Store';
import { Link } from 'react-router-dom'
import _debounce from 'lodash.debounce'
import config from '../../../src/config.json'
import moment from 'moment';

function FooterFixed() {
    const [companyDetails] = useContext(CompanyContext);
    const [websiteDetails,setwebsiteDetails] = useState();
    var x = companyDetails && companyDetails.company_id;
    useEffect(() => {
        (async function () {
            try {
                if (companyDetails) {
                    var company_id = companyDetails.company_id
                    var template_id = companyDetails.template_id
                    const staticPageData = await fetch(
                        config.apiEndpoint + '/userwb/get-fixed-footer-data/' + company_id + '/' + template_id
                    );
                    const json = await staticPageData.json();
                    const result = json.data;

                    if(result.room_details.data){
                        var all_rooms={data:{}};
                        var all_hotel_rooms=[]
                        for(var i = 0;i<result.room_details.data.length;i++){
                          for(var j =0;j<result.room_details.data[i].length;j++){
                            all_hotel_rooms.push(result.room_details.data[i][j]);
                          }
                        }
                        all_rooms.data=all_hotel_rooms;
                    }

                    let phone_number_hotel
                    let emailIdHotel 
                    let phone_number_whatsapp;
                    if(result && result.hotel_details){
                        if(result.hotel_details.original[0].mobile.includes(',')){
                            phone_number_whatsapp = result.hotel_details.original[0].mobile.slice(result.hotel_details.original[0].mobile.indexOf('-')+1, result.hotel_details.original[0].mobile.indexOf(','));

                            phone_number_hotel= result.hotel_details.original[0].mobile.substr(0, result.hotel_details.original[0].mobile.indexOf(','));
                        }else{
                            phone_number_hotel = result.hotel_details.original[0].mobile;

                            phone_number_whatsapp = result.hotel_details.original[0].mobile.slice(result.hotel_details.original[0].mobile.indexOf('-')+1, result.hotel_details.original[0].mobile.length);
                        }


                        if(result.hotel_details.original[0].email_id.includes(',')){
                            emailIdHotel = result.hotel_details.original[0].email_id.substr(0, result.hotel_details.original[0].email_id.indexOf(','));
                        }else{
                            emailIdHotel = result.hotel_details.original[0].email_id;
                        }
                    }


                    setwebsiteDetails({ "template_identity":result.template_identity,"hotel_menus":result.hotel_menu_details,"room_details":all_rooms,"hotel_details":result.hotel_details,'email':emailIdHotel,"phone":phone_number_hotel,"whatsapp":phone_number_whatsapp });

                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [x])


    const [screen_size, setScreensize] = useState(window.innerWidth)
    useEffect(() => {
        const handleResize = _debounce(() => setScreensize(window.innerWidth), 100)
        window.addEventListener('resize', handleResize);
    });
   
    let room_arr = websiteDetails && websiteDetails.room_details && websiteDetails.room_details.data
    var lowest_price = [];
    for (var i = 0; i < room_arr && room_arr.length; i++) {
        lowest_price.push(room_arr[i].rack_price)
    }
    lowest_price.sort();

    return (
        <div>
            {(screen_size < 768) ?
                <div className="container footerfix" style={{ position: "fixed", bottom: 0, width: "100%", backgroundColor: "black", zIndex: "999",height:"50px" }}>
                    <div className="row">
                        <div className="col-xs-4 col-sm-4 col-md-4">
                        {websiteDetails && websiteDetails.hotel_details && 
                        <a className="book_now_btn" href={"tel:"+websiteDetails.phone}
                        >Call Us</a>
                        }
                        </div>
                        <div className="col-xs-4 col-sm-4 col-md-4">
                        <span className="mailicon">
                        {websiteDetails && websiteDetails.hotel_details && 
                            <a href={"https://api.whatsapp.com/send?phone=91"+websiteDetails.whatsapp}><i className="fa fa-whatsapp"></i></a>
                        }
                        </span>
                        </div>
                        <div className="col-xs-4 col-sm-4 col-md-4">
                        <span className="mail-icon">
                        {websiteDetails && websiteDetails.hotel_details && 
                            <a href={"mailto:" + websiteDetails.email}><i className="fa fa-envelope-o"></i></a>
                        }
                        </span>
                        </div>
                    </div>
                </div>

                :
                ''}
        </div>
    )
}
export default FooterFixed;