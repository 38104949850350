import React, { useState, useEffect, useContext } from 'react';
import { CompanyContext } from '../../../global_setting/Store';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import config from '../../../../src/config.json'
import history from '../../../../src/history'

function ServiceScrollSection() {

    const [companyDetails] = useContext(CompanyContext);
    const [serviceScrollSectionData, setServiceScrollSectionData] = useState();
    let company_id_val = companyDetails && companyDetails.company_id;

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    const servicesSection = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-services-section-data/' + company_id + '/' + template_id
                    );
                    const result = await servicesSection.json();

                    setServiceScrollSectionData({ "selected_services": result && result.selected_services });
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])

    return (
        <div className="services-scroll">
            <div className="container allpics" >
                <span className="ratings"><a onClick={() => history.push(config.PATH + "gallery")} >VIEW ALL PHOTOS</a></span>
            </div>
            <div className="container-fluid services-scroll-container">
                <div className="services-scroll-list">
                    <div className="scrolling-wrapper">
                        {serviceScrollSectionData && serviceScrollSectionData.selected_services && serviceScrollSectionData.selected_services.map(function (item, i) {
                            return (
                                <div className="card" key={i}>
                                    <div className="text-center"><i className={item.icon} ></i></div>
                                    <h6>{item.title}</h6>
                                </div>
                            )
                        })}
                    </div>
                    <a className="pull-right" onClick={() => history.push(config.PATH + "services")}>VIEW ALL AMENITIES</a>
                </div>
                <br />
            </div>
        </div>
    )
}
export default ServiceScrollSection;
