import React, { useState,useContext } from 'react';
import { CompanyContext } from '../../../global_setting/Store';
import { Link } from 'react-router-dom'
import Modal from 'react-modal';
import moment from 'moment';
import config from '../../../../src/config.json'
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

const MobilePopup = (props) => {
    const [companyDetails] = useContext(CompanyContext);
    const [focusedInput, setfocusedInput] = useState(null)
    const [fromdate, setFromDate] = useState(moment())
    const [todate, setToDate] = useState(moment(fromdate).add(1, 'days'))

    let to_date = new Date(moment(fromdate).format('MM/DD/YYYY')).getTime();
    let from_date = new Date(moment(todate).format('MM/DD/YYYY')).getTime();
    let hotel_id_booking_engine = props && props.data
    var iframe
    let encryptData
    if (isNaN(from_date) && isNaN(to_date)) {

    }
    else {
        let allData = to_date + "|" + from_date + '|' + hotel_id_booking_engine + '|' + "" + '|' + "" + '|' + ""
        encryptData = btoa(allData);
        // iframe = encryptData

    }

    const customStylesMobile = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            minwidth: "360px",
            width: '100%',
            height: "80%"
        }
    };
    Modal.setAppElement('#root1')

    return (
        <div className="mobile-datepicker-widget">
            <Modal
                isOpen={props.modal_value}
                onRequestClose={() => props.setModalValue(false)}
                style={customStylesMobile}
                contentLabel="Example Modal"
            >
                <div className="modal-content mobile-content">
                    <div className="mobile-roombook-widget">
                        <DateRangePicker
                            startDate={fromdate}
                            startDateId="your_unique_start_date_id"
                            endDate={todate}
                            endDateId="your_unique_end_date_id"
                            onDatesChange={({ startDate, endDate }) => {
                                setFromDate(startDate)
                                setToDate(endDate)
                            }}
                            focusedInput={focusedInput}
                            onFocusChange={focusedInput => setfocusedInput(focusedInput)}
                            displayFormat='DD-MM-YYYY'
                            customArrowIcon="|"
                            block={true}
                            orientation="vertical"
                            verticalHeight={568}
                            readOnly={true}
                        />
                    </div>
                    <br />

                    <div className="book_button_widget">
                        {/* <Link className="book_now_btn"
                            to={{
                                pathname: config.PATH + 'booknow',
                                search: '?' + (iframe),
                            }}
                        >Book Now</Link> */}
                        <a className="book_now_btn" href={"https://" + companyDetails.subdomain_name +"/property?q="+encryptData}>Book Now</a>

                    </div>
                </div>
            </Modal>

        </div>
    )


}

export default MobilePopup;