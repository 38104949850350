import React, { useState, useContext, useEffect } from 'react'
import MetaTags from 'react-meta-tags';
import { CompanyContext, } from '../../global_setting/Store';
import Header from './Header'
import Footer from './Footer'
import history from '../../history'
import FooterFixed from './FooterFixed'
import config from '../../../src/config.json'
import OfferSection from './sections/OfferSection'
import AboutSection from './sections/AboutSection'
import ServiceSection from './sections/ServiceSection'
import PackageSection from './sections/PackageSection';
import RoomSection from './sections/RoomSection';
import GallerySection from './sections/GallerySection';
import TestimonialSection from './sections/TestimonialSection';
import FacebookSection from './sections/FacebookSection';
import BlogSection from './sections/BlogSection';
import EventSection from './sections/EventSection';
import NewsletterSection from './sections/NewsletterSection';
import ContactSection from './sections/ContactSection';
import NearbyPlacesSection from './sections/NearbyPlacesSection';
import PromotionalBannerSection from './sections/PromotionalBannerSection';
import PromotionSection from './sections/PromotionSection';
import AwardsSection from './sections/AwardsSection';
import BookingBenefitsSection from './sections/BookingBenefitsSection';
import CustomFooterSection from './sections/CustomFooterSection';
import CustomSection from './sections/CustomSection';

function Facilities() {


    const [companyDetails] = useContext(CompanyContext);
    let company_id_val = companyDetails && companyDetails.company_id;
    const [facilityPageData, setFacilityPageData] = useState();

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    let display_default_footer = true;

                    const pageData = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-facility-page-data/' + company_id + '/' + template_id
                    );
                    const result = await pageData.json();

                    if (result && result.facility_page_data && result.facility_page_data.page_section) {
                        for (let i = 0; i < result.facility_page_data.page_section.length; i++) {
                            if (result.facility_page_data.page_section[i].section_names === "Custom Footer") {
                                display_default_footer = false
                            }
                        }
                    }


                    setFacilityPageData({ "facility_page_data": result && result.facility_page_data, "page_section": result && result.facility_page_data && result.facility_page_data.page_section, "hotel_facilities": result && result.hotel_facilities, "all_hotels": result && result.all_hotels, "section_details": result && result.section_details, "display_default_footer": display_default_footer })


                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])


    // const websitefont = websiteDetails && websiteDetails.template_identity[0].theme_font
    let websitefont = ''

    const [scrolled, setscroll] = useState(false)
    if (scrolled === false) {
        window.scrollTo(0, 0)
    }

    window.addEventListener('scroll', function () {
        setscroll(true);
    });

    let video_banner_link = facilityPageData && facilityPageData.facility_page_data && facilityPageData.facility_page_data.video_banner_link
    video_banner_link = video_banner_link && video_banner_link.slice(video_banner_link.indexOf("."), video_banner_link.length)
    video_banner_link = video_banner_link && video_banner_link.slice(video_banner_link.indexOf("/") + 1, video_banner_link.length)

    let display_video_banner_link = facilityPageData && facilityPageData.facility_page_data && facilityPageData.facility_page_data.video_banner

    return (
        <div>
            {(facilityPageData === undefined) ? <div className="loader"></div> : <Header />}
            {facilityPageData && facilityPageData.facility_page_data &&
                <MetaTags>
                    <meta charSet="UTF-8" />
                    <title>{facilityPageData.facility_page_data.seo_title}</title>
                    <meta name="description" content={facilityPageData.facility_page_data.seo_description} />
                </MetaTags>
            }


            {facilityPageData && facilityPageData.facility_page_data && facilityPageData.facility_page_data.banner &&
                <section className="banner_area" style={{ backgroundImage: "url(" + config.wbImagesEndpoint + '/' + facilityPageData.facility_page_data.banner + ")" }}>
                    <div className="container">
                        <div className="banner_inner_content">
                            <h3 >{facilityPageData.facility_page_data.banner_title}</h3>
                            <ul>
                                <li className="active"><a onClick={() => history.push(config.PATH + 'home')}>Home</a></li>
                                <li><a style={{ color: 'white' }}>{facilityPageData.facility_page_data.banner_title}</a></li>
                            </ul>
                        </div>
                    </div>
                </section>}


            {facilityPageData && facilityPageData.facility_page_data && facilityPageData.facility_page_data.video_banner && <section>
                <div className="video-date-picker-wrapper">
                    <div className="video-wrapper">
                        <video playsInline="playsinline" autoPlay="autoplay" muted="muted" loop="loop">
                            <source src={config.wbImagesEndpoint + '/' + facilityPageData.facility_page_data.video_banner} type="video/mp4" />
                            <source src={config.wbImagesEndpoint + '/' + facilityPageData.facility_page_data.video_banner} type="video/ogg" />
                        </video>
                    </div>
                </div>
            </section>}


            {!display_video_banner_link && video_banner_link && <section>
                <div className="video-date-picker-wrapper">
                    <div className="video-container-youtube">
                        <iframe type="text/html" className="video-youtube" src={"https://www.youtube.com/embed/" + video_banner_link + "?&amp;autoplay=1&amp;loop=1&amp;rel=0&amp;showinfo=0&amp;controls=0&amp;color=white&amp;iv_load_policy=3&amp;"} allow="autoplay" frameBorder="0" allowFullScreen></iframe>
                    </div>
                </div>
            </section>}


            {facilityPageData && facilityPageData.facility_page_data && facilityPageData.facility_page_data.page_title && <section className="spec_resort_area">
                <div className="container">
                    <div className="center_page_title">
                        <h2>{facilityPageData.facility_page_data.page_title}</h2>
                        <p dangerouslySetInnerHTML={{ __html: facilityPageData.facility_page_data.page_description }}></p>
                    </div>
                </div>
            </section>}



            {facilityPageData && <FacilitiesList data={facilityPageData.hotel_facilities} hotel_list={facilityPageData.all_hotels} section_data={facilityPageData.section_details} />}

            {facilityPageData && facilityPageData.page_section && facilityPageData.page_section.map(function (item, i) {

                if (item.section_names === "Promotional Banner") {
                    return (
                        <PromotionalBannerSection key={i} />
                    )
                }

                if (item.section_names === "Newsletter") {
                    return (
                        <NewsletterSection key={i} />
                    )
                }

                if (item.section_names === "About Us") {
                    return (
                        <AboutSection key={i} />
                    )
                }

                if (item.section_names === "Rooms") {
                    return (
                        <RoomSection key={i} />
                    )
                }


                if (item.section_names === "Gallery") {
                    return (
                        <GallerySection key={i} />
                    )
                }

                if (item.section_names === "Contact Us") {
                    return (
                        <ContactSection key={i} />
                    )
                }

                if (item.section_names === "Services") {
                    return (
                        <ServiceSection key={i} />
                    )
                }


                if (item.section_names === "Nearby Places") {
                    return (
                        <NearbyPlacesSection key={i} />
                    )
                }

                if (item.section_names === "Testimonial") {
                    return (
                        <TestimonialSection key={i} />
                    )
                }


                if (item.section_names === "Event") {
                    return (
                        <EventSection key={i} />
                    )
                }


                if (item.section_names === "Package") {
                    return (
                        <PackageSection key={i} />
                    )
                }


                if (item.section_names === "Blog") {
                    return (
                        <BlogSection key={i} />
                    )
                }

                if (item.section_names === "Offer") {
                    return (
                        <OfferSection key={i} />
                    )
                }


                if (item.section_names === "Facebook") {
                    return (
                        <FacebookSection key={i} />
                    )
                }

                if (item.section_names === "Promotions") {
                    return (
                        <PromotionSection key={i} />

                    )
                }

                if (item.section_names === "Awards") {
                    return (
                        <AwardsSection key={i} />
                    )
                }


                if (item.section_names === "Booking Benfits") {
                    return (
                        <BookingBenefitsSection key={i} />
                    )
                }


                if (item.section_type === "custom") {
                    return (
                        <CustomSection key={i} section_id={item.id} name={item.section_names}/>
                    )
                }

                
                if (item.section_names === "Custom Footer") {
                    return (
                        <CustomFooterSection key={i} />
                    )
                }
            })}

            {facilityPageData && facilityPageData.display_default_footer && facilityPageData.display_default_footer === true && <Footer />}
            {facilityPageData && <FooterFixed />}



        </div>

    );
}



function FacilitiesList(props) {

    let list_data = props.data;
    let hotel_list = props.hotel_list
    let section_data = props.section_data
    const [hotelId, setHotelId] = useState(hotel_list && hotel_list[0].hotel_id);

    let websitefont = '';
    const hotelName = (e) => {
        setHotelId(parseInt(e));
    }

    return (
        <section>

            <div className="container margin-top">
                <div className="explor_title row m0">
                    {section_data && <div className="pull-left">
                        <div className="left_ex_title">
                            <h2 style={{ fontFamily: websitefont }}>{section_data.section_title}</h2>
                        </div>
                    </div>}

                    {hotel_list && hotel_list.length > 1 &&
                        <div className="pull-right">
                            <div className="page-header text-center text-uppercase">
                                <select className="form-control select-form select-hotel" onChange={(e) => { hotelName(e.target.value) }}>
                                    {hotel_list && hotel_list.map(function (item, i) {
                                        return (
                                            <option key={i} value={item.hotel_id}>{item.hotel_name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                    }
                </div>


                <div className="room_service_list">
                    {list_data && list_data.length > 0 && list_data.map(function (items, id) {
                        if (hotelId === items.hotel_id) {
                            return (
                                <div className="row room_service_list_inner services-listing" key={id}>
                                    <div className="col-sm-6 col-md-offset-right-1">
                                        <div className="resot_list">
                                            <ul>

                                                {items.facilities.length > 0 && items.facilities.map(function (item, i) {
                                                    if (i % 2 === 0) {
                                                        return (
                                                            <li key={i}><a ><i className="fa fa-caret-right" aria-hidden="true"></i>{item}</a></li>
                                                        )
                                                    }
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-offset-right-1">
                                        <div className="resot_list">
                                            <ul>
                                                {items.facilities.length > 0 && items.facilities.map(function (item, i) {
                                                    if (i % 2 !== 0) {
                                                        return (
                                                            <li key={i}><a ><i className="fa fa-caret-right" aria-hidden="true"></i>{item}</a></li>
                                                        )
                                                    }
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )

                        }
                    })
                    }



                </div>
            </div>

        </section>
    )

}
export default Facilities;


