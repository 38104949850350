import React, { useState, useEffect, useContext } from 'react';
import { CompanyContext } from '../../global_setting/Store';
import config from '../../../src/config.json'
import history from '../../history';

function Header() {
    const [companyDetails] = useContext(CompanyContext);
    const [websiteDetails, setwebsiteDetails] = useState();
    var x = companyDetails && companyDetails.company_id;
    useEffect(() => {
        (async function () {
            try {
                if (companyDetails) {
                    var company_id = companyDetails.company_id
                    var template_id = companyDetails.template_id
                    const hotelData = await fetch(
                        config.apiEndpoint + '/userwb/get-header-data/' + company_id + '/' + template_id

                    );
                    const json = await hotelData.json();
                    const result = json.data;
                    if (result && result.header_menu_details.length > 5) {
                        let header_menu = result.header_menu_details;
                        var extra_header_menu = header_menu.splice(6, 6);

                        extra_header_menu.forEach(function (item, i) {
                            item["menu"] = "active";
                        })
                    } else {
                        extra_header_menu = [];
                    }

                    var all_menu_details = {
                        "header": {
                            "header_menus": result && result.header_menu_details,
                            "other_menus": extra_header_menu
                        }
                    }
                    setwebsiteDetails({ "menu_details": all_menu_details, "template_identity": result && result.template_identity, "hotel_details": result && result.hotel_details, "hotel_menus": result && result.hotel_menu_details, "awards": result.awards && result.awards.original, "packages": result.packages_list && result.packages_list.original });
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [x])

    const websitefont = websiteDetails && websiteDetails.template_identity[0].theme_font


    const [headerbutton, setHeaderButton] = useState(false);
    const [dropdowntoggle, settoggle] = useState(false)
    let url = window.location.href;
    var splitUrl = url.split('/');
    var activemenu = splitUrl[splitUrl.length - 1]


    //function use to redirect the page
    const redirectTo = (page) => {
        if (page.page_url === '' || page.page_url === ' ') {
            history.push(config.PATH + page.page);
        } else {
            var page_url = page.page_url
            if (page_url.includes("http://") || page_url.includes("https://")) {
                window.open(page_url)
            }
            else {
                if (page_url.includes("www")) {
                    page_url = page_url.trimStart()
                    window.open("http://" + page_url)
                }

                else {
                    history.push(config.PATH + page_url);
                }
            }
        }
    }

    return (
        <div>

            <header className="main_header_area">
                <div className="header_menu affix-top">
                    <nav className="navbar navbar-default">
                        <div className="container" >
                            <div className="navbar-header" >
                                <button type="button" className="navbar-toggle " data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="true" onClick={() => (headerbutton) ? setHeaderButton(false) : setHeaderButton(true)}>
                                    <span className="sr-only">Toggle navigation</span>
                                    <span className="icon-bar" ></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>

                                {websiteDetails && websiteDetails.template_identity[0] &&
                                    <a className="navbar-brand logo-wrap" onClick={() => history.push(config.PATH + 'home')}>
                                        <img src={config.wbImagesEndpoint + '/' + websiteDetails.template_identity[0].logo} alt={websiteDetails.template_identity[0].logo_alt} />

                                        {websiteDetails.awards && websiteDetails.awards.length > 0 && websiteDetails.awards[0].extra_page_image.length > 0 && websiteDetails.awards[0].extra_page_image.map(function (items, i) {
                                            return (

                                                <span key={i}>
                                                    <img src={config.wbImagesEndpoint + '/' + items} alt={items.extra_page_image_alt} />
                                                </span>
                                            )
                                        })}
                                    </a>
                                }

                            </div>
                            <div className={headerbutton ? "navbar-collapse collapse in" : "navbar-collapse collapse"} id="bs-example-navbar-collapse-1" aria-expanded={headerbutton ? "true" : "false"}>
                                <ul className="nav navbar-nav">
                                    {websiteDetails && websiteDetails.menu_details && websiteDetails.menu_details.header.header_menus.map(function (item, i) {
                                        if (item.page_id === 1) {
                                            item.page = item.page_url;
                                        }
                                        if (item.menu_name == "Packages ") {
                                            return (
                                                <li className={(dropdowntoggle) ? "dropdown submenu open" : "dropdown submenu"} key={i}>
                                                    <a className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded={(dropdowntoggle) ? "true" : "false"} onClick={() => (dropdowntoggle) ? settoggle(false) : settoggle(true)} >
                                                        Packages <i className="fa fa-chevron-down" aria-hidden="true"></i></a>
                                                    <ul className="dropdown-menu">

                                                        {websiteDetails && websiteDetails.packages && websiteDetails.packages.map(function (item, i) {
                                                            var send_extra_page_title = item.extra_page_title.replace(/[^a-zA-Z0-9 ]/g, "");
                                                            send_extra_page_title = send_extra_page_title.replace(/ +/g, "_");
                                                            send_extra_page_title = send_extra_page_title.toLowerCase();
                                                            return (
                                                                <li key={i}>
                                                                    <a onClick={() => history.push(config.PATH + '' + send_extra_page_title)}
                                                                    >{item.extra_page_title}</a>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </li>
                                            )
                                        }
                                        else {
                                            return (
                                                <li key={i}
                                                    className={(item.page === activemenu ? 'active' : '')} >
                                                    <a onClick={() => redirectTo(item)}
                                                        style={{ fontFamily: websitefont }}>{item.menu_name}
                                                    </a>
                                                </li>
                                            )
                                        }
                                    })
                                    }

                                    {
                                        (websiteDetails && websiteDetails.menu_details
                                            && websiteDetails.menu_details.header.other_menus.length > 0) ?
                                            <li className={(dropdowntoggle) ? "dropdown submenu open" : "dropdown submenu"}>
                                                <a className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded={(dropdowntoggle) ? "true" : "false"} onClick={() => (dropdowntoggle) ? settoggle(false) : settoggle(true)}>
                                                    <i className="fa fa-bars more-menus" aria-hidden="true"></i></a>
                                                <ul className="dropdown-menu">
                                                    {
                                                        websiteDetails && websiteDetails.menu_details.header.other_menus.map(function (item, i) {
                                                            if (item.page_id === 1) {
                                                                item.page = item.page_url;
                                                            }
                                                            return <li key={i}>
                                                                <a onClick={() => redirectTo(item)}>{item.menu_name}</a>

                                                            </li>
                                                        })
                                                    }
                                                </ul>
                                            </li>
                                            : ''
                                    }
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>

            </header>

        </div>
    )
}

export default Header