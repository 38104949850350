import React, { useState, useEffect, useContext } from 'react';
import { CompanyContext } from '../../../global_setting/Store';
import config from '../../../../src/config.json'

const PromotionSection = (props) => {
    const [companyDetails] = useContext(CompanyContext);
    const [promotionSectionData, setPromotionSectionData] = useState();
    let company_id_val = companyDetails && companyDetails.company_id;

    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    const promotionSection = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-promotion-section-data/' + company_id + '/' + template_id
                    );
                    const result = await promotionSection.json();

                    setPromotionSectionData({ "hotel_promotions": result && result.hotel_promotions, "section_details": result && result.section_details });

                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])

    let websitefont = '';
    let section_details = promotionSectionData && promotionSectionData.section_details

    let display_section_title_des = props && props.data


    return (

        <div>

            {!display_section_title_des && <div className="container">
                {section_details && <div className="center_title">
                    <h2>{section_details.section_title}</h2>
                    <p dangerouslySetInnerHTML={{ __html: section_details.section_description }}></p>
                </div>}
            </div>}


            {promotionSectionData && promotionSectionData.hotel_promotions && promotionSectionData.hotel_promotions.length > 0 && <section className={section_details && section_details.background_image && "parallax-bg"} style={section_details && { backgroundImage: "url(" + config.wbImagesEndpoint + '/' + section_details.background_image + ")" }}>
                <div className="container container-space margin-top">

                <div className="clearfix widget-wrapper wt3tb three-col ">
                        {promotionSectionData && promotionSectionData.hotel_promotions && promotionSectionData.hotel_promotions.map(function (item, i) {
                            return (
                                <div className="col-sm-4 left-box text-content" key={i}>
                                    <div className="text-content-wrapper">
                                        <p></p>
                                        <div className="table-responsive-promotion">
                                            <table className="table">
                                                <tbody>
                                                    <tr style={{ height: "215px" }}>
                                                        <td bgcolor={item.background_color} style={{ textAlign: "center" }}>
                                                            <p>&nbsp;</p>
                                                            <h3>
                                                                <span style={{ color: "#ffffff" }}>
                                                                    <span style={{ fontSize: "20px" }}>&nbsp;
                                                           </span>
                                                                    {item.title}
                                                                </span>
                                                            </h3>
                                                            <span style={{ color: "#ffffff" }}>
                                                                <p dangerouslySetInnerHTML={{ __html: item.description }}></p> </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <p></p>
                                    </div>
                                </div>
                            )
                        })}

                    </div>

                </div>
            </section>}

        </div>

    )
}

export default PromotionSection;